<template>
  <div class="about-us">
    <div class="banner-wrap">
      <div class="banner">
        <div class="text">
          <div class="title animate__animated animate__fadeInLeftBig">科技大数据场景应用领先者</div>
        </div>
      </div>
    </div>
    <section class="introduce-zzt animate__animated animate__fadeIn">
      <h1 class="title">关于中知数通</h1>
      <div class="content">
        <div class="introduce">
          <div class="line-text">
            <p>
              科技大数据场景应用的领先者，是国家知识产权局知识产权出版社的战略共建单位，是一家国资背景的科技大数据骨干企业。
            </p>
          </div>
          <div class="line-text">
            <p>
              中知数通聚合了工信部、科技部、国家知识产权局等多个权威部门认定的信息和数据，通过大数据与人工智能技术，构建了中国200万家高价值科技企业成长基因库，独创了专利价值评估模型、企业科技力与成长性评估模型、企业“硬科技属性”评价模型，自主研发了科技金融全场景的产品矩阵。
            </p>
          </div>
          <div class="line-text">
            <p>
              中知数通致力于为促进国内“科技-产业-金融”协同，提供数据支撑、咨询和撮合服务。为金融机构提供科技金融数据赋能，帮忙金融机构解决“找不到，看不懂，风控难”三大痛点。
            </p>
          </div>
        </div>
        <div class="card-main">
          <div class="card-item hover_effect" v-for="(item, index) in introduceList" :key="index"
            :style="`background-image: url('${item.bgImage}')`">
            <div class="card-item-title">
              {{ item.title }}
            </div>
            <div class="card-item-subtitle">
              {{ item.subTitle }}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="dynamic">
      <h1 class="title">近期动态</h1>
      <div class="content">
        <el-carousel indicator-position="outside" style="width: 100%;" height="400px" arrow="always"
          class="custom-dynamic-swiper">
          <el-carousel-item v-for="item in partition(dynamicList, 3)" :key="item.title">
            <div class="dynamic-swiper">
              <div v-for="(its, idx) in item" :key="idx" class="dynamic-item hover_effect">
                <div class="img-box">
                  <img :src="its.banner" width="378">
                </div>
                <main>
                  <p class="card-title">{{ its.title }}</p>
                  <p class="detail">{{ its.detail }}</p>
                  <footer>
                    <span class="time">{{ its.time }}</span>
                    <span class="link" @click="toPage(its.link)">查看详情</span>
                  </footer>
                </main>
              </div>
            </div>

          </el-carousel-item>
        </el-carousel>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'AboutUs',
  data() {
    return {
      mediaList: [
        {
          img: 'https://images.zzt.com.cn/ox/2022/12/08/dynamic_1.png',
          title: '中国企业科技力量化评测联合课题组成立',
          content: '中国技术市场协会、中国知识产权研究会、新华社《经济参考报》、知识产权出版社与中知数通（北京）信息技术有限公司日前成立联合课题组，共同开展“中国企业科技力量化评测”研究项目。',
          time: '2022-11-28',
          origin: '来源：中知数通',
          url: 'https://mp.weixin.qq.com/s/MvT6Yy7sAwpuHwxp1m52wQ'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media1.png',
          title: '国内首个知识产权数字化流通生态平台建设启动',
          content: '国家知识产权局直属单位和知识产权信息服务专业机构知识产权出版社有限责任公司与在线资产交易平台阿里资产达成数字化战略合作，联合知识产权大数据应用服务商中知数通、新型数字科技企业繁星超越共同打造知识产权数字化流通生态平台。',
          time: '2022-09-09',
          origin: '来源：经济参考报',
          url: 'http://www.jjckb.cn/2022-09/09/c_1310661059.htm'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media2.png',
          title: '专利大数据应用系统“深眸”上线',
          content: '由国家知识产权局知识产权出版社、中知数通（北京）信息技术有限公司联合打造的专利大数据应用系统“深眸”日前正式发布。',
          time: '2022-04-28',
          origin: '来源：经济参考报',
          url: 'http://www.jjckb.cn/2022-04/28/c_1310577086.htm'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media3.png',
          title: '国家知识产权局知识产权出版社与中知数通签署战略合作协议',
          content: '国家知识产权局知识产权出版社日前与中知数通(北京)信息技术有限公司签署战略合作协议，双方将共建基于国家知识产权局知识产权出版社权威数据的中知通知识产权金融创新服务平台，为开展知识产权金融服务的机构保驾护航。',
          time: '2022-01-13',
          origin: '来源：中国财经网',
          url: 'http://finance.china.com.cn//roll/20220113/5728010.shtml '
        }
      ],
      introduceList: [
        {
          title: '聚合权威部门海量数据',
          subTitle: '国家知识产权局知识产权出版社战略共建单位',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/26/aboutUs-card-bg-one.png',
        }, {
          title: '科技大数据场景应用领先者',
          subTitle: '促进“科技-产业-金融”协同',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/26/aboutUs-card-bg-two.png',
        }, {
          title: '国内科技力量化评估的领军者',
          subTitle: '赋能“找到”、“看懂”、“跟踪”科技企业',
          bgImage: 'https://images.zzt.com.cn/gnW/2024/04/26/aboutUs-card-bg-three.png',
        }
      ],
      dynamicList: [
      {
          banner: 'https://images.zzt.com.cn//officialWebsite/0620news.png',
          title: '服务中国大科创时代，让科技金融触手可及——“深眸系统”银行版重磅发布',
          detail: '由融至道咨询、山西银行、中知数通联合主办的第七届新银行发展策略大会在龙城太原成功举行。中知数通在大会上推出科技金融“百日扬帆”公益计划，并重磅发布了深眸系统-科技企业成长性与科技力评测系统（银行版）。',
          time: '2024-06-25',
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247485006&idx=1&sn=5ad1e35f75e8504ed24bd83ce9cb7909&chksm=ce445bfbf933d2ed81364fcefbe7b4e2a7749b5e029c0ace8997bf54b0b1f9870ba05b62512b&token=2086822754&lang=zh_CN&from=industrynews&version=4.1.10.8002&platform=win&nwr_flag=1#wechat_redirect'
        },
        {
          banner: 'https://images.zzt.com.cn/gnW/2024/04/30/0427news.png',
          title: '2024中关村论坛《科创企业创新力评价标准》正式发布',
          detail: '4月27日，《科创企业创新力评价标准》正式发布，将助力企业科技创新，加快推动企业科技成果转化，推动企业提升综合竞争力，加快发展新质生产力。',
          time: '2024-04-27',
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247484996&idx=1&sn=9754ef4379726ca9045590d918277627&chksm=ce445bf1f933d2e7fac0fb10bfd5f29599a12cbd32dacd507c6cf1601d24098c7277022b4d12&token=1537796208&lang=zh_CN#rd'
        },
        {
          banner: 'https://images.zzt.com.cn/ks/2023/06/19/rank3.png',
          title: '2023网络安全企业科技能力报告',
          detail: '中知智库与安全419作为主要支撑研究单位，联合中关村网络安全与信息化产业联盟发布《2023网络安全企业科技能力报告》，发现有效专利超八成为发明专利，且总估值超亿元；近五年新增公开专利数量连年上涨；专利聚焦于数字信息传输、电数字数据处理。',
          time: '2023-05-31',
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247484907&idx=1&sn=5fd75c2823cda2704cc2a2ccc233dcce&chksm=ce44585ef933d148359e989f348724718af54c0e05a6d98864e2730e2d9b889967bd361f1bcb&vid=1688854700419086&deviceid=1e5ce778-1918-447a-b62b-7c9e9314f5a0&version=4.1.6.6018&platform=win#rd'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/26/car.png',
          title: '汽车芯片国产化不足，哪些企业将成为破局者？',
          detail: '汽车芯片逐渐成为最重要的零部件之一。在“国产替代”成为当前国际科技竞争的主旋律背景下，国内企业正在从汽车芯片的各个领域逐个击破。从长远来看，在汽车芯片技术研发、车规级认证和量产方面取得进展后，国内企业有机会实现全方面打破国外垄断的愿景。',
          time: '2022-12-08',
          link: 'https://mp.weixin.qq.com/s/hqva491jh13U5MVWh7q5nA'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/08/dynamic_1.png',
          title: '中国企业科技力量化评测联合课题组成立',
          detail: '中国技术市场协会、中国知识产权研究会、新华社《经济参考报》、知识产权出版社与中知数通（北京）信息技术……',
          time: '2022-11-28',
          link: 'https://mp.weixin.qq.com/s/MvT6Yy7sAwpuHwxp1m52wQ'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/11/03/rank22.png',
          title: '构筑我国能源安全的“主动脉”，这些特高压领域企业榜上有名！',
          detail: '中国作为世界第一能源消耗大国，守护能源安全任重道远。因为特高压技术的从无到有，从跟随到引领，我国一步一步的构筑起了纵横交错，横贯东西的能源输送“主动脉”。在全球能源安全异常重要的当下，更显弥足珍贵。',
          time: '2022-10-27',
          link: 'https://mp.weixin.qq.com/s?__biz=Mzg2NjgxNTE5NA==&mid=2247484424&idx=1&sn=239873fb03815cc4374332c1b32eb00b&chksm=ce4459bdf933d0ab0bfee00763d9ca2bc73cbaf61eb69145bde2fed817f64c1600935cbd9b78#rd'
        },
        {
          banner: 'https://images.zzt.com.cn/ks/2023/06/06/rank1.png',
          title: '新能源电池企业专利指数排行榜发布，产业格局仍有短板尚未突破！',
          detail: '新能源电池企业专利指数排行榜发布，比亚迪超过“宁王”，成“榜上一哥”，但产业格局仍有短板尚未突破！',
          time: '2022-09-22',
          link: 'https://mp.weixin.qq.com/s/Pl3TYfqqq-i0AUGp_flQ1w'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/07/dynamic2.png',
          title: '国内首个知识产权数字化流通生态平台建设启动',
          detail: '国家知识产权局直属单位和知识产权信息服务专业机构知识产权出版社有限责任公司与在线资产交易平台阿里资产达成数……',
          time: '2022-09-09',
          link: 'http://www.jjckb.cn/2022-09/09/c_1310661059.htm'
        },
        {
          banner: 'https://images.zzt.com.cn/ox/2022/12/07/dynamic3.png',
          title: '专利大数据应用系统“深眸”上线',
          detail: '由国家知识产权局知识产权出版社、中知数通（北京）信息技术有限公司联合打造的专利大数据应用系统“深眸”日前正式……',
          time: '2022-04-28',
          link: 'http://www.jjckb.cn/2022-04/28/c_1310577086.htm'
        },
      ],
    }
  },
  methods: {
    toPage(link) {
      window.open(link, '_blank')
    },
    partition(arr, chunkSize) {
      let result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        let chunk = arr.slice(i, i + chunkSize);
        result.push(chunk);
      }
      return result;
    }
  }
}
</script>
<style lang="scss" scoped>
.about-us {
  background-color: #fff;
}

section {
  padding-top: Translate(74px);

  .content {
    width: $container-width;
    margin: 0 auto;


    .img-container {
      display: flex;
      justify-content: space-between;

      img {
        margin-left: Translate(20px);
      }
    }
  }

  .title {
    font-size: Translate(36px);
    color: #333333;
    font-weight: 500;
    margin: 0 auto Translate(46px);
    min-width: $container-width;
  }
}

.banner {
  position: relative;
  width: 100%;
  min-width: $container-width;
  height: Translate(500px);
  background: url("https://images.zzt.com.cn/ox/2022/12/23/aboutUs-bg.png") no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto;

  .text {
    width: $container-width;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: left;
    padding-top: Translate(172px);

    .title {
      font-size: Translate(40px);
      font-weight: 500;
      margin-bottom: Translate(18px);
      text-align: center;
    }

    .sub-title {
      font-size: Translate(16px);
      line-height: Translate(34px);
    }
  }
}

.introduce-zzt {
  width: 100%;
  height: Translate(622px);
  box-sizing: border-box;
  background: #FFFFFF;

  .introduce {
    width: 100%;
    text-align: left;

    .line-text {
      display: flex;

      p {
        font-family: 'PingFangSC-Regular';
        font-size: Translate(16px);
        color: #333333;
        line-height: Translate(30px);
        font-weight: 400;
        width: Translate(1186px);
      }

      &::before {
        content: '';
        display: inline-block;
        width: Translate(6px);
        height: Translate(6px);
        background: #999999;
        border-radius: Translate(3px);
        margin-right: Translate(8px);
        position: relative;
        top: Translate(12px);
      }

      &:nth-child(n+2) {
        margin-top: Translate(12px);
      }
    }
  }

  .card-main {
    display: flex;
    justify-content: space-between;
    margin-top: Translate(34px);

    .card-item {
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      width: Translate(384px);
      height: Translate(200px);
      text-align: left;

      .card-item-title {
        font-family: 'PingFangSC-Medium';
        font-size: Translate(20px);
        color: #333333;
        font-weight: 500;
        margin-top: Translate(115px);
        margin-left: Translate(24px);
      }

      .card-item-subtitle {
        font-family: 'PingFangSC-Regular';
        font-size: Translate(16px);
        color: #333333;
        line-height: Translate(32px);
        font-weight: 400;
        margin-top: Translate(4px);
        margin-left: Translate(24px);
      }
    }


  }
}

.dynamic {
  background-color: #F5F6F9;
  padding-bottom: Translate(90px);

  p {
    margin: 0;
    text-align: left;
  }

  .content {
    width: Translate(1328px);
    display: flex;
    justify-content: center;


    .dynamic-swiper {
      width: $container-width;
      // width: 100%;
      display: flex;
      overflow: hidden;

      .dynamic-item {
        width: Translate(378px);
        height: Translate(374px);
        margin-right: Translate(33px);

        .img-box {
          width: 100%;
          height: Translate(210px);
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        main {
          background-color: #fff;
          padding: Translate(28px) Translate(20px);
          text-align: left;

          .card-title {
            width: Translate(338px);
            font-size: Translate(16px);
            font-weight: 500;
            margin-bottom: Translate(8px);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .detail {
            font-size: Translate(12px);
            height: Translate(40px);
            color: #858996;
            line-height: Translate(20px);
            margin-bottom: Translate(24px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: wrap;
          }

          footer {
            display: flex;
            justify-content: space-between;
            font-size: Translate(14px);
            color: #666666;

            .time {
              font-size: Translate(14px);
              color: #666666;
            }

            .link {
              color: #4088EB;

              &:hover {
                cursor: pointer;
                color: #337EE5;
              }
            }
          }
        }
      }
    }
  }

}

::v-deep .custom-dynamic-swiper {
  .el-carousel__container {
    .el-carousel__item {
      display: flex;
      justify-content: center;
    }

    .el-carousel__arrow--left {
      left: 0;
    }

    .el-carousel__arrow--right {
      right: 0;
    }
  }

  .el-carousel__indicators {
    .is-active {
      .el-carousel__button {
        background: #4088EB;
      }
    }
  }

  .el-carousel__button {
    width: 8px;
    height: 8px;
    background: #BBBBBB;
    border-radius: 50%;
  }

  .is-active .el-carousel__button {
    width: 8px;
    height: 8px;
    background: #4088EB;
    border-radius: 50%;
  }
}
</style>
