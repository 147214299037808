<template>
  <div class="search-package-page">
    <header>
      <div class="header-main">
        <div class="logo">
          <img src="https://images.zzt.com.cn/gnW/2024/01/25/logo.png" alt="logo" />
        </div>
      </div>
    </header>

    <main>
      <div class="page-main">
        <div class="left-search">
          <div class="title">查询数据服务</div>
          <div class="form-main">
            <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm" label-position="left">
              <el-form-item label="查询账号" prop="usename">
                <el-input v-model="formData.usename" placeholder="请输入查询账号"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="captchaCode">
                <el-input type="text" v-model="formData.captchaCode" placeholder="请输入图形验证码" autocomplete="off" class="code-item" @input="handleInput"></el-input>
                <img :src="captchaSrc" class="captcha cursor-pointer" @click="getCaptcha">
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitForm()" style="width: 100%;" v-loading="isLoading">立即查询</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="right-result">
          <div class="title">数据服务信息</div>
          <div class="table-main">
            <el-table :data="tableData" style="width: 100%;" v-loading="isLoading">
              <el-table-column type="expand">
                <template slot-scope="props">
                  <div class="more-api-info">
                    <span v-for="(item,index) in props.row.interfaceNameList" :key="index"
                      :style="`${index > 0 ? 'margin-left: 12px;' : ''}`">{{ item + `${index + 1 < props.row.interfaceNameList.length ? ';' : ''} ` }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column v-for="(item,index) in columns" :key="index" :label="item.label" :prop="item.prop">
                <template slot-scope="props">
                  <div v-if="item.prop ==='serviceEndTime'">
                    {{ props.row.serviceEndTime?props.row.serviceEndTime.slice(0,10):'-' }}
                  </div>
                  <div v-else>
                    {{ props.row[item.prop] }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script>
import * as api from '@/api'
export default {
  data() {
    return {
      isError: true,
      captchaSrc: '',
      rules: {
        usename: [
          { required: true, message: '请输入查询账号', trigger: 'blur' },
        ],
        captchaCode: [
          { required: true, message: '请输入图形验证码', trigger: 'blur' },
        ],
      },
      formData: {
        usename: '',
        captchaCode: '',
      },
      columns: [{
        label: '服务套餐',
        prop: 'packageName',
        width: '100px',
      }, {
        label: ' 服务使用限制/次',
        prop: 'totalTimes',
        width: '100px',
      }, {
        label: '累计调用/次',
        prop: 'callTimes',
        width: '100px',
      }, {
        label: '到期日',
        prop: 'serviceEndTime',
        width: '100px',
      }, {
        label: '是否失效',
        prop: 'type',
        width: '100px',
      }],
      tableData: [],
      isLoading: false,
    }
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.isError) {
            this.tableData = []
            this.$refs['ruleForm'].fields[1].validateMessage = '验证码错误'
            this.$refs['ruleForm'].fields[1].validateState = 'error'
            return false
          }
          this.toSearch()
        }
      })
    },
    handleInput() {

    },
    async getCaptcha() {
      const { data } = await api.getCaptcha()
      const url = window.URL.createObjectURL(new Blob([data], { type: 'image/jepg' }))
      this.captchaSrc = url
    },
    async handleInput(value) {
      //当图形验证码和手机号填写完毕时自动发送验证码
      if (value.length === 4) {
        const { success } = await api.checkCaptcha({ captcha: this.formData.captchaCode })
        if (success) {
          this.$refs['ruleForm'].clearValidate('captchaCode')
          this.isError = false
        } else {
          if (this.isError) {
            this.$refs['ruleForm'].fields[1].validateMessage = '验证码错误'
            this.$refs['ruleForm'].fields[1].validateState = 'error'
            this.getCaptcha()
          }
        }
      }
    },
    async toSearch() {
      let params = {
        identifyCode: this.formData.usename
      }
      this.isLoading = true
      try {
        const { success, data: { data, message } } = await api.getInvokerPackageUsedInfo(params)
        // let res = await api.getInvokerPackageUsedInfo(params)
        // console.log('res', res)
        if (success) {
          let packageList = data.effectivePackageList.map(item => { return { ...item, type: '否' } })
          let invalidList = data.invalidPackageList.map(item => { return { ...item, type: '是' } })
          this.tableData = [...packageList, ...invalidList]
          this.isLoading = false
        } else {
          this.$message.error(message);
          this.tableData = []
          this.isLoading = false
        }
      } catch {
        this.isLoading = false
      }
      this.isError = true
      this.getCaptcha()
    }
  },
  mounted() {
    this.getCaptcha()
  }

}
</script>

<style lang="scss" scoped>
.search-package-page {
  //   background-color: #fff;
  header {
    background-color: #fff;
    border-bottom: 1px solid #eee7e7;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    .header-main {
      height: $header-height;
      width: $container-width;
      margin: 0 auto;
      z-index: 5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        .split-icon {
          padding: 0 28px;
          color: #dfdfdf;
        }
        img {
          // width: 740px;
          width: 620px;
        }
      }
    }
  }
  main {
    margin-top: $header-height;
    overflow: hidden;
    min-height: calc(100vh - $header-height - Translate(330px));
    height: 100%;
    .page-main {
      //   height: calc(100vh - $header-height);
      width: $container-width;
      display: flex;
      justify-content: space-between;
      text-align: left;
      margin: 0 auto;
      padding-top: Translate(50px);
      .left-search {
        width: 35%;
        // background-color: #666;
        .title {
          font-size: Translate(26px);
        }
        .form-main {
          margin-top: Translate(30px);
        }
      }
      .right-result {
        width: 60%;
        // background-color: #999;
        .title {
          font-size: Translate(26px);
        }
        .table-main {
          margin-top: Translate(30px);
          .more-api-info {
            padding: 0 Translate(20px);
          }
        }
      }
      .captcha {
        width: Translate(80px);
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
</style>
<style lang="scss">
.code-item .el-input__inner {
  padding-left: 15px;
}
</style>